import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./firebase";
import { collection, query, where, getDocs, getDoc } from "firebase/firestore";
import { doc as fsDoc } from "firebase/firestore";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
  Grid,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Menu from "./Menu";
import Footer from "./Footer";

function Matchups() {
  const { leagueId } = useParams();
  const [user] = useAuthState(auth);
  const [matchup, setMatchup] = useState(null);
  const [user1Squads, setUser1Squads] = useState([]);
  const [user2Squads, setUser2Squads] = useState([]);
  const [user1TeamName, setUser1TeamName] = useState([]);
  const [user2TeamName, setUser2TeamName] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(null);

  const positions = ["QB", "WR", "RB", "TE", "OL", "DB", "DL", "S", "K", "ST"];

  useEffect(() => {
    const fetchCurrentWeek = async () => {
      try {
        const currentWeekRef = fsDoc(db, "currentWeek", "currentWeek");
        const currentWeekDoc = await getDoc(currentWeekRef);
        if (currentWeekDoc.exists()) {
          setCurrentWeek(currentWeekDoc.data().currentWeek);
        } else {
          console.log("No currentWeek document found!");
          setCurrentWeek(1);
        }
      } catch (error) {
        console.error("Error fetching current week:", error);
      }
    };

    fetchCurrentWeek();
  }, []);

  useEffect(() => {
    // Fetch the matchup data once currentWeek is available
    if (currentWeek !== null) {
      fetchMatchupForWeek(currentWeek);
    }
  }, [currentWeek, leagueId, user]);

  const fetchSquadsForUser = async (userId, leagueId) => {
    const squadUsersRef = collection(db, "squadUsers");
    const q = query(
      squadUsersRef,
      where("userId", "==", userId),
      where("leagueId", "==", leagueId)
    );
    const querySnapshot = await getDocs(q);

    const squadPromises = querySnapshot.docs.map(async (doc) => {
      const squadId = doc.data().squadId;
      const squadDoc = await getDoc(fsDoc(db, "squads", squadId));
      return squadDoc.data();
    });

    return await Promise.all(squadPromises);
  };

  const fetchTeamNames = async (userId, leagueId) => {
    const leagueUsersRef = collection(db, "leagueUsers");
    const q = query(
      leagueUsersRef,
      where("userId", "==", userId),
      where("leagueId", "==", leagueId)
    );
    const querySnapshot = await getDocs(q);

    const leaguePromises = querySnapshot.docs.map(async (doc) => {
      return doc.data().teamName || "";
    });

    return await Promise.all(leaguePromises);
  };

  const fetchMatchupForWeek = async (week) => {
    const matchupsRef = collection(db, "matchups");

    // First try querying with user1Id
    let q = query(
      matchupsRef,
      where("leagueId", "==", leagueId),
      where("user1Id", "==", user.uid),
      where("week", "==", week)
    );
    let querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      // If no matchup found, try querying with user2Id
      q = query(
        matchupsRef,
        where("leagueId", "==", leagueId),
        where("user2Id", "==", user.uid),
        where("week", "==", week)
      );
      querySnapshot = await getDocs(q);
    }

    if (!querySnapshot.empty) {
      const matchupDoc = querySnapshot.docs[0];
      const matchupData = matchupDoc.data();
      setMatchup(matchupData);

      // 2. Fetch rosters for both users in the matchup
      const user1Id = matchupData.user1Id;
      const user2Id = matchupData.user2Id;

      const [user1SquadsData, user2SquadsData] = await Promise.all([
        fetchSquadsForUser(user1Id, leagueId),
        fetchSquadsForUser(user2Id, leagueId),
      ]);

      const [user1TeamNameData, user2TeamNameData] = await Promise.all([
        fetchTeamNames(user1Id, leagueId),
        fetchTeamNames(user2Id, leagueId),
      ]);

      setUser1Squads(user1SquadsData);
      setUser2Squads(user2SquadsData);
      setUser1TeamName(user1TeamNameData);
      setUser2TeamName(user2TeamNameData);
    } else {
      console.log(
        "No matchup data found for this week. Squads and TeamNames are null"
      );
      setMatchup(null); // No matchup found for this week
      setUser1Squads([]);
      setUser2Squads([]);
      setUser1TeamName([]);
      setUser2TeamName([]);
    }
  };

  const handleNextWeek = () => {
    if (currentWeek < 16) {
      fetchMatchupForWeek(currentWeek + 1);
      setCurrentWeek(currentWeek + 1);
    }
  };

  const handlePrevWeek = () => {
    if (currentWeek > 1) {
      fetchMatchupForWeek(currentWeek - 1);
      setCurrentWeek(currentWeek - 1);
    }
  };

  if (!matchup) {
    return (
      <>
        <Menu />
        {currentWeek ? (
          <Typography
            variant="overline"
            gutterBottom
            sx={{ m: "10px" }}
            align="center"
          >
            Enjoy your bye week!
          </Typography>
        ) : (
          <Typography
            variant="overline"
            gutterBottom
            sx={{ m: "10px" }}
            align="center"
          >
            No matchups found in this league!
          </Typography>
        )}
        <Button
          variant="contained"
          size="small"
          component={Link}
          to={"/leagues"}
        >
          My Leagues
        </Button>
      </>
    );
  }

  const user1TotalPoints = user1Squads.reduce(
    (sum, squad) => sum + (squad.currentPoints || 0),
    0
  );
  const user2TotalPoints = user2Squads.reduce(
    (sum, squad) => sum + (squad.currentPoints || 0),
    0
  );

  return (
    <div>
      <Menu />
      <Typography variant="h5" align="center" gutterBottom sx={{ m: "10px" }}>
        Matchup - Week {matchup.week}
      </Typography>
      {/* Previous/Next buttons */}
      <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 2 }}>
        {currentWeek > 1 && (
          <IconButton onClick={handlePrevWeek}>
            <NavigateBeforeIcon />
          </IconButton>
        )}
        {currentWeek < 12 && (
          <IconButton onClick={handleNextWeek}>
            <NavigateNextIcon />
          </IconButton>
        )}
      </Stack>
      {/* Team names at the top */}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={5} textAlign="center">
          <Typography variant="overline" gutterBottom>
            {user1TeamName && user1TeamName.length > 0 && user1TeamName[0] // Check if the first element is a non-empty string
              ? user1TeamName[0] // Display the first element if it exists
              : matchup.user1Id === user.uid
              ? "My Roster"
              : "Opponent Roster"}
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid> {/* Spacer */}
        <Grid item xs={5} textAlign="center">
          <Typography variant="overline" gutterBottom>
            {user2TeamName && user2TeamName.length > 0 && user2TeamName[0]
              ? user2TeamName[0]
              : matchup.user2Id === user.uid
              ? "My Roster"
              : "Opponent Roster"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {" "}
          {/* Use a single Grid container */}
          <List>
            {positions.map((position) => {
              const user1Squad = user1Squads.find(
                (squad) => squad.position === position
              );
              const user2Squad = user2Squads.find(
                (squad) => squad.position === position
              );

              return (
                <ListItem key={position}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mt: 2 }}
                  >
                    <Grid item xs={5} align="center">
                      {/* User 1 Squad */}
                      {user1Squad ? (
                        <>
                          <ListItemAvatar>
                            <Avatar
                              alt={`${user1Squad.squadName} logo`}
                              src={`/logos/${user1Squad.logo}`}
                              sx={{ width: 50, height: 50 }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={`Points: ${user1Squad.currentPoints.toFixed(
                              1
                            )}`}
                            secondary={`Projected: ${user1Squad.projected.toFixed(
                              1
                            )}`}
                            secondaryTypographyProps={{ fontSize: "0.8rem" }}
                          />
                        </>
                      ) : (
                        <Button
                          component={Link}
                          to={`/squads/${leagueId}`}
                          size="small"
                        >
                          No {position} Rostered
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      {/* Position */}
                      <Typography variant="subtitle1" align="center">
                        {position}
                      </Typography>
                    </Grid>
                    <Grid item xs={5} align="center">
                      {/* User 2 Squad */}
                      {user2Squad ? (
                        <>
                          <ListItemAvatar>
                            <Avatar
                              alt={`${user2Squad.squadName} logo`}
                              src={`/logos/${user2Squad.logo}`}
                              sx={{ width: 50, height: 50 }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={`Points: ${user2Squad.currentPoints.toFixed(
                              1
                            )}`}
                            secondary={`Projected: ${user2Squad.projected.toFixed(
                              1
                            )}`}
                            secondaryTypographyProps={{ fontSize: "0.8rem" }}
                          />
                        </>
                      ) : (
                        <Button
                          component={Link}
                          to={`/squads/${leagueId}`}
                          size="small"
                        >
                          No {position} Rostered
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </List>
          {/* Total points */}
          <Grid container justifyContent="space-between">
            <Grid item xs={6} align="center">
              <Typography variant="body2">
                Total Points: {user1TotalPoints.toFixed(1)}
              </Typography>
            </Grid>
            <Grid item xs={6} align="center">
              <Typography variant="body2">
                Total Points: {user2TotalPoints.toFixed(1)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}

export default Matchups;
