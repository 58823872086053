import React, { useState, useEffect, useCallback } from "react";
import Menu from "./Menu";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import RosterCard from "./RosterCard";
import Footer from "./Footer"

function Roster() {
  const [user] = useAuthState(auth);
  const [squadsByLeague, setSquadsByLeague] = useState({});
  const [leagueNames, setLeagueNames] = useState({});
  const { leagueId, userId } = useParams();

  const fetchSquads = useCallback(async () => {
    // Wrap fetchSquads in useCallback
    if (user) {
      const squadUsersRef = collection(db, "squadUsers");
      let q;
      if (leagueId && userId) {
        // Filter by both leagueId and userId
        q = query(
          squadUsersRef,
          where("userId", "==", userId),
          where("leagueId", "==", leagueId)
        );
      } else if (leagueId) {
        // If leagueId is provided, filter by it
        q = query(
          squadUsersRef,
          where("userId", "==", user.uid),
          where("leagueId", "==", leagueId)
        );
      } else {
        // Otherwise, fetch all squadUsers for the user
        q = query(squadUsersRef, where("userId", "==", user.uid));
      }
      const querySnapshot = await getDocs(q);

      const squadUserData = [];
      querySnapshot.forEach((doc) => {
        squadUserData.push({ id: doc.id, ...doc.data() });
      });

      // Group squadUsers by leagueId
      const groupedSquads = squadUserData.reduce((acc, squadUser) => {
        const leagueId = squadUser.leagueId;
        if (!acc[leagueId]) {
          acc[leagueId] = [];
        }
        acc[leagueId].push(squadUser);
        return acc;
      }, {});

      // Fetch squad details for each squadUser
      const squadsByLeaguePromises = Object.entries(groupedSquads).map(
        async ([leagueId, squadUsers]) => {
          const squadPromises = squadUsers.map(async (squadUser) => {
            const squadDoc = await getDoc(doc(db, "squads", squadUser.squadId));
            return { ...squadDoc.data(), squadUserId: squadUser.id };
          });
          const squads = await Promise.all(squadPromises);
          return [leagueId, squads];
        }
      );
      const squadsByLeagueArray = await Promise.all(squadsByLeaguePromises);
      const squadsByLeagueObject = squadsByLeagueArray.reduce(
        (acc, [leagueId, squads]) => {
          acc[leagueId] = squads;
          return acc;
        },
        {}
      );

      setSquadsByLeague(squadsByLeagueObject);

      // Fetch league names
      const leagueNamesPromises = Object.keys(groupedSquads).map(
        async (leagueId) => {
          const leagueDoc = await getDoc(doc(db, "leagues", leagueId));
          return leagueDoc.exists()
            ? leagueDoc.data().leagueName
            : "Unknown League";
        }
      );
      const leagueNamesArray = await Promise.all(leagueNamesPromises);
      const leagueNamesObject = Object.keys(groupedSquads).reduce(
        (acc, leagueId, index) => {
          acc[leagueId] = leagueNamesArray[index];
          return acc;
        },
        {}
      );
      setLeagueNames(leagueNamesObject);
    }
  }, [user, leagueId, userId]);

  // Sort squads within each league
  const sortedSquadsByLeague = Object.fromEntries(
    Object.entries(squadsByLeague).map(([leagueId, squads]) => {
      const sortedSquads = [...squads].sort((a, b) => {
        const positionComparison = a.position.localeCompare(b.position);
        if (positionComparison !== 0) {
          return positionComparison;
        } else {
          return a.team.localeCompare(b.team);
        }
      });
      return [leagueId, sortedSquads];
    })
  );
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const fetchUserName = async () => {
      if (userId) {
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          setUserName(userDoc.data().displayName || userDoc.data().email);
        } else {
          setUserName("Unknown User");
        }
      }
    };

    fetchUserName();
  }, [userId]);

  useEffect(() => {
    fetchSquads();
  }, [user, fetchSquads]);

  const handleDropSquad = async (squadUserId) => {
    try {
      await deleteDoc(doc(db, "squadUsers", squadUserId));
      // Re-fetch squads to update the UI (or you can manually update the squadsByLeague state)
      fetchSquads();
    } catch (error) {
      console.error("Error dropping squad:", error);
    }
  };

  return (
    <div>
      <Menu />

      <Typography sx={{ margin: "10px" }} variant="h5" gutterBottom>
        {!userId ? "My Roster" : `${userName}'s Roster`}
      </Typography>

      {Object.entries(leagueNames).map(([leagueId, leagueName]) => (
        <RosterCard
          key={leagueId}
          leagueId={leagueId}
          leagueName={leagueName}
          squads={sortedSquadsByLeague[leagueId]}
          handleDropSquad={handleDropSquad}
        />
      ))}
      <Footer />
    </div>
  );
}

export default Roster;
