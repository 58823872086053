import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./firebase";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  serverTimestamp,
  deleteDoc,
} from "firebase/firestore";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
  Button,
} from "@mui/material";
import Menu from "./Menu";
import AutoFill from "./AutoFill";
import Footer from "./Footer"

function Squads() {
  const [user] = useAuthState(auth);
  const { leagueId } = useParams();
  const [availableSquads, setAvailableSquads] = useState([]);
  const navigate = useNavigate();

  const fetchAvailableSquads = async () => {
    let isMounted = true;

    if (user && leagueId && isMounted) {
      // 1. Fetch all squads
      const squadsRef = collection(db, "squads");
      const squadsSnapshot = await getDocs(squadsRef);
      const allSquads = squadsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // 2. Fetch user's squads in the league
      const squadUsersRef = collection(db, "squadUsers");
      const q = query(
        squadUsersRef,
        where("userId", "==", user.uid),
        where("leagueId", "==", leagueId)
      );
      const squadUsersSnapshot = await getDocs(q);
      const selectedSquadIds = squadUsersSnapshot.docs.map(
        (doc) => doc.data().squadId
      );

      // 3. Filter and sort available squads
      const available = allSquads
        .filter((squad) => !selectedSquadIds.includes(squad.id))
        .sort((a, b) => {
          const positionComparison = a.position.localeCompare(b.position);
          return positionComparison || a.team.localeCompare(b.team);
        });

      setAvailableSquads(available);
    }
    return () => {
      isMounted = false;
    };
  };

  useEffect(() => {
    fetchAvailableSquads();
  }, [user, leagueId]);

  const handleAddSquad = async (squadId) => {
    try {
      const newSquad = availableSquads.find((squad) => squad.id === squadId);

      // 1. Fetch existing squadUser documents
      const squadUsersRef = collection(db, "squadUsers");
      const q = query(
        squadUsersRef,
        where("leagueId", "==", leagueId),
        where("userId", "==", user.uid)
      );
      const querySnapshot = await getDocs(q);

      // 2. Delete existing squadUser with the same position
      const existingSquadDocs = querySnapshot.docs.filter((doc) => {
        const squadData = doc.data();
        return squadData.position === newSquad.position;
      });

      await Promise.all(existingSquadDocs.map((doc) => deleteDoc(doc.ref)));

      // 3. Create a new squadUser document
      await addDoc(collection(db, "squadUsers"), {
        userId: user.uid,
        leagueId: leagueId,
        squadId: squadId,
        position: newSquad.position,
        createdAt: serverTimestamp(),
      });

      // 4. Update available squads
      setAvailableSquads(
        availableSquads.filter((squad) => squad.id !== squadId)
      );

      navigate(`/roster/${leagueId}`);
    } catch (error) {
      console.error("Error adding/replacing squad:", error);
    }
  };

  return (
    <div>
      <Menu />
      <Typography sx={{ margin: "10px" }} variant="h5" gutterBottom>
        Available Squads
      </Typography>

      <Button
        sx={{ margin: "10px" }}
        component={Link}
        variant="contained"
        to={`/roster/${leagueId}`}
        // to={`/rosterExperimental/${leagueId}`}
      >
        Back to Roster
      </Button>

      <AutoFill leagueId={leagueId} onAutoFill={fetchAvailableSquads} />

      <List>
        {availableSquads.map((squad) => (
          <ListItem key={squad.id}>
            <Button onClick={() => handleAddSquad(squad.id)}>Add</Button>
            <ListItemAvatar>
              <Avatar
                alt={`${squad.squadName} logo`}
                src={`/logos/${squad.logo}`}
                sx={{ width: 50, height: 50 }}
              />
            </ListItemAvatar>
            <ListItemText primary={squad.squadName} />
            <ListItemText primary={squad.currentPoints} />
          </ListItem>
        ))}
      </List>
      <Footer />
    </div>
  );
}

export default Squads;
