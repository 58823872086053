import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Menu from "./Menu";
import { TextField, Button, Box, Typography } from "@mui/material";
import AdminToolbar from "./AdminToolbar";
import Footer from "./Footer";

function Profile() {
  const [user] = useAuthState(auth);
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const fetchDisplayName = async () => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setDisplayName(userDocSnap.data().displayName || "");
          setEmail(userDocSnap.data().email || "");
        }
      }
    };

    fetchDisplayName();
  }, [user]);

  const handleSave = async () => {
    if (user && displayName) {
      try {
        const userDocRef = doc(db, "users", user.uid);

        // Fetch the current user document to get the admin field
        const userDoc = await getDoc(userDocRef);
        const adminValue = userDoc.data().admin || false; // Get the current admin value

        await updateDoc(userDocRef, {
          displayName: displayName,
          email: email,
          admin: adminValue,
        });

        // Optionally display a success message
        console.log("Display name updated successfully!");
      } catch (error) {
        console.error("Error updating display name:", error);
        // Handle the error (e.g., display an error message)
      }
    }
  };

  return (
    <div>
      <Menu />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 4,
        }}
      >
        <Typography variant="h5" gutterBottom>
          My Profile
        </Typography>
        <Typography
          sx={{ marginTop: "10px", marginBottom: "20px" }}
          component="div"
        >
          {email}
        </Typography>{" "}
        <TextField
          label="Display Name"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2, maxWidth: 400 }}
        />
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={!displayName}
        >
          Save
        </Button>
        {/* AdminToolbar */}
        <AdminToolbar user={user} />
      </Box>
      <Footer />
    </div>
  );
}

export default Profile;
