import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";

function Menu() {
    const [user] = useAuthState(auth);
    
      const handleLogout = async () => {
        try {
          await auth.signOut();
          // redirect the user to the home page after logout
          window.location.href = "/";
        } catch (error) {
          console.error("Error logging out:", error);
        }
      };

  return (
    <div>
      <AppBar position="static">
        {" "}
        {/* Add an AppBar */}
        <Toolbar sx={{ paddingLeft: "5px", paddingRight: "5px" }}>
          <Link to="/">
            <img
              src="/logos/sec_fantasy_logo.png" //
              alt="Home"
              style={{ height: "35px" }} // Adjust size and margin as needed
            />
          </Link>

          {/* Conditionally render buttons based on login status */}
          {user ? (
            <Box sx={{ flexGrow: 1 }}>
              {" "}
              {/* This will push the buttons to the right */}
              <Button
                sx={{ marginLeft: "6px" }}
                component={Link}
                to="/leagues"
                color="inherit"
              >
                Leagues
              </Button>
              <Button
                sx={{ margin: "2px" }}
                component={Link}
                to="/rosterChoice"
                // to="/rosterExperimental"
                color="inherit"
              >
                Roster
              </Button>
              <Button
                sx={{ margin: "2px" }}
                component={Link}
                to="/profile"
                color="inherit"
              >
                Profile
              </Button>
              <Button
                sx={{ marginLeft: "2px" }}
                color="inherit"
                onClick={handleLogout}
              >
                Log Out
              </Button>
            </Box>
          ) : (
            <Button
              component={Link}
              to="/login" // Link to your Authentication component
              variant="contained"
              color="primary"
              sx={{ marginLeft: "10px" }}
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Menu;
