import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { Button, Box, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import Footer from "./Footer"

function RosterChoice() {
  const [user] = useAuthState(auth);
  const [userLeagues, setUserLeagues] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserLeagues = async () => {
      if (user) {
        const leagueUsersRef = collection(db, "leagueUsers");
        const q = query(leagueUsersRef, where("userId", "==", user.uid));
        const querySnapshot = await getDocs(q);
        const leagueIds = querySnapshot.docs.map((doc) => doc.data().leagueId);

        const leaguePromises = leagueIds.map(async (leagueId) => {
          const leagueDoc = await getDoc(doc(db, "leagues", leagueId));
          return { id: leagueId, ...leagueDoc.data() };
        });
        const leaguesData = await Promise.all(leaguePromises);

        setUserLeagues(leaguesData);
      }
      setIsLoading(false); // Set loading to false after fetching
    };

    fetchUserLeagues();
  }, [user]);

  useEffect(() => {
    if (!isLoading) {
      // Only navigate if not loading
      if (userLeagues.length === 1) {
        navigate(`/roster/${userLeagues[0].id}`);
      }
    }
  }, [userLeagues, navigate, isLoading]); // Add isLoading to dependency array

  if (isLoading) {
    return (
      <>
        <Menu />
      </>
    ); // Don't render anything while loading except for the menu
  }

  return (
    <>
      <Menu />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 4,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Choose a League
        </Typography>
        {userLeagues.length > 0 ? ( // Conditionally render league buttons or "Join League" button
          userLeagues.map((league) => (
            <Button
              key={league.id}
              component={Link}
              to={`/roster/${league.id}`}
              variant="contained"
              sx={{ margin: "10px", width: "95%" }}
            >
              {league.leagueName}
            </Button>
          ))
        ) : (
          <Button component={Link} to="/newLeague" variant="contained">
            Join a League
          </Button>
        )}
      <img
        src="/logos/sec_fantasy_logo.png" // Path to your logo
        alt="SEC Fantasy Logo"
        style={{ maxWidth: "150px" }} // Set max width
        />
        </Box>
      <Footer />
    </>
  );
}

export default RosterChoice;
