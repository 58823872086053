import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./firebase";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import {
  Typography,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";

function NewLeague() {
  const [user] = useAuthState(auth);
  const [leagueName, setLeagueName] = useState("");
  const [leagueType, setLeagueType] = useState("public"); // Default to public
  const [publicLeagues, setPublicLeagues] = useState([]);
  const navigate = useNavigate();

  const handleCreateLeague = async () => {
    try {
      // 1. Create the league document
      const leagueRef = await addDoc(collection(db, "leagues"), {
        leagueName: leagueName,
        leagueType: leagueType,
        creatorId: user.uid,
        createdAt: serverTimestamp(),
      });

      // 2. Create the leagueUsers document
      await addDoc(collection(db, "leagueUsers"), {
        leagueId: leagueRef.id,
        userId: user.uid,
        joinedAt: serverTimestamp(),
        teamName: "Roster Name"
      });

      // Redirect to the Leagues page
      navigate("/leagues");
    } catch (error) {
      console.error("Error creating league:", error);
      // Handle the error (e.g., display an error message)
    }
  };

useEffect(() => {
  const fetchPublicLeagues = async () => {
    if (user) {
      // 1. Fetch all public leagues
      const leaguesRef = collection(db, "leagues");
      const q = query(leaguesRef, where("leagueType", "==", "public"));
      const querySnapshot = await getDocs(q);
      const allPublicLeagues = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // 2. Fetch league IDs the user is already a member of
      const leagueUsersRef = collection(db, "leagueUsers");
      const userLeaguesQuery = query(
        leagueUsersRef,
        where("userId", "==", user.uid)
      );
      const userLeaguesSnapshot = await getDocs(userLeaguesQuery);
      const joinedLeagueIds = userLeaguesSnapshot.docs.map(
        (doc) => doc.data().leagueId
      );

      // 3. Filter out leagues the user has already joined
      const availableLeagues = allPublicLeagues.filter(
        (league) => !joinedLeagueIds.includes(league.id)
      );

      setPublicLeagues(availableLeagues);
    }
  };

  fetchPublicLeagues();
}, [user]);

  const handleJoinLeague = async (leagueId) => {
    try {
      // Create the leagueUsers document
      await addDoc(collection(db, "leagueUsers"), {
        leagueId: leagueId,
        userId: user.uid,
        joinedAt: serverTimestamp(),
        teamName: "Roster Name",
      });

      // Redirect to the Add page
      navigate(`/squads/${leagueId}`);
    } catch (error) {
      console.error("Error joining league:", error);
    }
  };

  return (
    <div>
      <Menu />

      {/* Join Public League Section */}
      <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
        Join a Public League
      </Typography>
      <List>
        {publicLeagues.map((league) => (
          <ListItem key={league.id}>
            <ListItemText primary={league.leagueName} />
            <Button onClick={() => handleJoinLeague(league.id)}>Join</Button>
          </ListItem>
        ))}
      </List>

      {/* Create New League Section */}
      <Typography variant="h6" gutterBottom>
        Create New League
      </Typography>
      <TextField
        label="League Name"
        value={leagueName}
        onChange={(e) => setLeagueName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <FormControl>
        <FormLabel id="league-type-radio">League Type</FormLabel>
        <RadioGroup
          row
          aria-labelledby="league-type-radio"
          name="league-type-group"
          value={leagueType}
          onChange={(e) => setLeagueType(e.target.value)}
        >
          <FormControlLabel value="public" control={<Radio />} label="Public" />
          <FormControlLabel
            value="private"
            control={<Radio />}
            label="Private"
          />
        </RadioGroup>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateLeague}
        disabled={!leagueName} // Disable if league name is empty
      >
        Create League
      </Button>
    </div>
  );
}

export default NewLeague;
