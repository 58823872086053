import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "./firebase";
import Menu from "./Menu";
import { Button, Box, Typography } from "@mui/material";
import Footer from "./Footer";
import {
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";

function ACC() {
    const [user] = useAuthState(auth);
    const navigate = useNavigate();

  // sign in with google
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          // console.log(JSON.stringify(user));

          // User is signed in, check if they exist in Firestore
          const userRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userRef);

          if (!userDoc.exists()) {
            // New user, create a user record in Firestore
            try {
              await setDoc(userRef, {
                email: user.email,
                displayName: user.displayName,
                admin: false,
              });
              console.log("User record created in Firestore");
            } catch (error) {
              console.error("Error creating user record:", error);
            }
          } else {
            console.log("User already exists in Firestore");
          }

          // Redirect to the leagues page or another appropriate page
          navigate("/leagues");
        } else {
          // User is signed out
          navigate("/");
        }
      });
      await signInWithPopup(auth, provider);
      // Redirect to the leagues page after successful login
      navigate("/leagues");
    } catch (error) {
      console.error(error);
      // Redirect back to the homepage on error
      navigate("/");
    }
  };

  return (
    <>
      <Menu />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "75vh", // Ensure it takes full viewport height
          textAlign: "center", // Center text within the box
        }}
      >
        <img
          src="/logos/sec_fantasy_logo.png" // Path to your logo
          alt="SEC Fantasy Logo"
          style={{ maxWidth: "225px" }} // Set max width
        />
        <Typography variant="h6" gutterBottom sx={{ m: 3 }}>
          ACC Fantasy coming soon...
        </Typography>
        <Typography variant="subtitle" gutterBottom sx={{ m: 3 }}>
          Check out SEC Fantasy while you wait!
        </Typography>

        {/* Conditionally render buttons based on login status */}
        {user ? (
          <>
            <Button
              component={Link}
              to="/rosterChoice"
              // to="/rosterExperimental"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              My Roster
            </Button>
          </>
        ) : (
          // copy of auth
          <>
            <Button
              onClick={signInWithGoogle}
              variant="contained"
              color="primary"
            >
              Sign in with Google
            </Button>
          </>
        )}
      </Box>
      <Footer />
    </>
  );
}

export default ACC;
