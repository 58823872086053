import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  Tooltip,
  Box,
  Card,
  CardContent,
  CardActionArea,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

function RosterCard({ leagueId, leagueName, squads, handleDropSquad }) {
  return (
    <Card key={leagueId} sx={{ marginBottom: 2 }}>
      <CardActionArea component={Link} to={`/roster/${leagueId}`}>
        <CardContent>
          <Typography variant="h6" component="div">
            {leagueName}
          </Typography>
          <Button
            variant="contained"
            size="small"
            component={Link}
            to={`/squads/${leagueId}`}
          >
            Find Squads
          </Button>{" "}
          {/* Go to latest matchup */}
          <Button
            variant="contained"
            size="small"
            component={Link}
            to={`/matchups/${leagueId}`}
          >
            Matchups
          </Button>
          {squads && squads.length > 0 ? ( // Check if squads exist and have items
            <List>
              {squads.map((squad) => (
                <ListItem key={squad.squadUserId}>
                  <Button onClick={() => handleDropSquad(squad.squadUserId)}>
                    Drop
                  </Button>
                  <ListItemAvatar>
                    <Avatar
                      alt={`${squad.squadName} logo`}
                      src={`/logos/${squad.logo}`}
                      sx={{ width: 50, height: 50 }}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={squad.squadName} />
                  <ListItemSecondaryAction>
                    <Tooltip title="Current Points">
                      <Typography variant="body2">
                        {squad.currentPoints || 0}
                      </Typography>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 2,
                  mr: 2,
                }}
              >
                <Typography variant="h8">
                  Total Points:{" "}
                  {parseFloat(
                    squads.reduce(
                      (sum, squad) => sum + (squad.currentPoints || 0),
                      0
                    )
                  ).toFixed(1)}
                </Typography>
              </Box>
            </List>
          ) : (
            <Typography variant="body2">No squads selected.</Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default RosterCard;
