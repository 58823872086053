import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "./firebase";
import * as firestore from "firebase/firestore";
import { collection, query, where, getDocs, getDoc } from "firebase/firestore";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import Footer from "./Footer";

function LeagueUsers() {
  const { leagueId } = useParams();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchLeagueUsers = async () => {
      if (leagueId) {
        const leagueUsersRef = collection(db, "leagueUsers");
        const q = query(leagueUsersRef, where("leagueId", "==", leagueId));
        const querySnapshot = await getDocs(q);

        const userPromises = querySnapshot.docs.map(async (doc) => {
          const userId = doc.data().userId;
          const userDoc = await getDoc(firestore.doc(db, "users", userId));
          return { id: userId, ...userDoc.data() };
        });

        const usersData = await Promise.all(userPromises);
        setUsers(usersData);
      }
    };

    fetchLeagueUsers();
  }, [leagueId]);

  return (
    <div>
      <Menu />
      <Typography sx={{ margin: "10px" }} variant="h5" gutterBottom>
        League Members
      </Typography>
      <List>
        {users.map((user) => (
          <ListItem key={user.id}>
            <ListItemText primary={user.displayName || user.email} />
            <Button component={Link} to={`/roster/${leagueId}/${user.id}`}>
              Roster
            </Button>
          </ListItem>
        ))}
      </List>
      <Footer />
    </div>
  );
}

export default LeagueUsers;
