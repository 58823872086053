import React, { useState, useEffect } from "react";
import Menu from "./Menu";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./firebase";
import {
  collection,
  query,
  where,
  getDoc,
  getDocs,
  doc,
} from "firebase/firestore";
import {
  List,
  Typography,
  Button,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
} from "@mui/material";
import { Link } from "react-router-dom";
import Footer from "./Footer"

function Leagues() {
  const [user, loading] = useAuthState(auth);
  const [leagues, setLeagues] = useState([]);

  useEffect(() => {
    const fetchLeagues = async () => {
      if (user) {
        const userId = user.uid;
        const leagueUsersRef = collection(db, "leagueUsers");
        const q = query(leagueUsersRef, where("userId", "==", userId));
        const querySnapshot = await getDocs(q);

        const leagueIds = [];
        querySnapshot.forEach((doc) => {
          leagueIds.push(doc.data().leagueId);
        });

        // Fetch league details
        const leaguePromises = leagueIds.map(async (leagueId) => {
          const leagueDoc = await getDoc(doc(db, "leagues", leagueId));
          return { id: leagueId, ...leagueDoc.data() };
        });
        const leaguesData = await Promise.all(leaguePromises);

        setLeagues(leaguesData);
      }
    };
    fetchLeagues();
  }, [user]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <Menu />

      <Typography sx={{ margin: "10px" }} variant="h5" gutterBottom>
        My Leagues
      </Typography>
      <List>
        {leagues.map((league) => (
          <Card key={league.id} sx={{ marginBottom: 2 }}>
            {" "}
            {/* League name */}
            <CardActionArea component={Link} to={`/roster/${league.id}`}>
              <CardContent>
                <Typography variant="h6" component="div">
                  {league.leagueName}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              {" "}
              {/* Go to my roster */}
              <Button
                variant="contained"
                size="small"
                component={Link}
                to={`/roster/${league.id}`}
                // to={`/rosterExperimental/${league.id}`}
              >
                My Roster
              </Button>
              {/* </CardActions>
            <CardActions> */}{" "}
              {/* Go to squads */}
              <Button
                variant="contained"
                size="small"
                component={Link}
                to={`/squads/${league.id}`}
              >
                Find Squads
              </Button>
            </CardActions>
            <CardActions>
              {" "}
              {/* Go to latest matchup */}
              <Button
                variant="outlined"
                size="small"
                component={Link}
                to={`/matchups/${league.id}`}
              >
                Matchups
              </Button>{" "}
              {/* Go to other users' rosters */}
              <Button
                variant="outlined"
                size="small"
                component={Link}
                to={`/leagueUsers/${league.id}`}
              >
                League Teams
              </Button>
            </CardActions>
          </Card>
        ))}
      </List>
      <Button
        sx={{ margin: "10px" }}
        size="small"
        component={Link}
        to={`/newLeague`}
      >
        Find or Create a League
      </Button>
      <Footer />
    </div>
  );
}

export default Leagues;
