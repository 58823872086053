import React, { useState } from "react";
import { db } from "./firebase";
import {
  collection,
  addDoc,
  Timestamp,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import {
  Typography,
  Button,
  TextField,
  Box,
  FormControl, 
  InputLabel, 
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom"; // Import
import Footer from "./Footer";

function AdminGenerateSeason() {
  const [startOfSeason, setStartOfSeason] = useState(null);
  const [numWeeks, setNumWeeks] = useState(16);
  const [isGenerating, setIsGenerating] = useState(false); // Add generating state
  const navigate = useNavigate(); // Initialize useNavigate

  const handleGenerateSeason = async () => {
    if (startOfSeason) {
      setIsGenerating(true); // Disable the button
      try {
        // 1. Delete existing currentWeek documents
        const currentWeekSnapshot = await getDocs(
          collection(db, "currentWeek")
        );
        const deletePromises = currentWeekSnapshot.docs.map(async (doc) => {
          await deleteDoc(doc.ref);
        });
        await Promise.all(deletePromises);

        // 2. Generate new currentWeek documents
      let startDate = startOfSeason
        .startOf("week") // sunday
        .set("hour", 4) // Set hour to 4 AM
        .set("minute", 0)
        .set("second", 0);;

      for (let week = 1; week <= numWeeks; week++) {
        const endDate = startDate
          .endOf("week")
          .add(1, "day") // Add 1 day to get to Sunday
          .set("hour", 4) // Set hour to 4 AM
          .set("minute", 0)
          .set("second", 0);

        await addDoc(collection(db, "currentWeek"), {
          startDate: Timestamp.fromDate(startDate.toDate()),
          endDate: Timestamp.fromDate(endDate.toDate()),
          week: week,
        });

        startDate = startDate.add(1, "week"); // Update startDate for the next week
      }

        console.log("Season data generated successfully!");
        navigate("/profile"); // Redirect to Profile page
      } catch (error) {
        console.error("Error generating season data:", error);
      } finally {
        setIsGenerating(false); // Re-enable the button
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 4,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Generate Season Data
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start of Season"
            value={startOfSeason}
            onChange={(newValue) => setStartOfSeason(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <FormControl fullWidth sx={{ marginTop: 2, maxWidth: 200 }}>
          <InputLabel id="num-weeks-label">Weeks</InputLabel>
          <Select
            labelId="num-weeks-label"
            id="num-weeks-select"
            value={numWeeks}
            label="Weeks"
            onChange={(e) => setNumWeeks(e.target.value)}
          >
            {[...Array(20).keys()].map((i) => (
              <MenuItem key={i + 1} value={i + 1}>
                {i + 1}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleGenerateSeason}
          disabled={!startOfSeason || isGenerating} // Disable while generating
          sx={{ marginTop: 2 }}
        >
          {isGenerating ? ( // Show loading indicator while generating
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Generate Season"
          )}
        </Button>
      </Box>
      <Footer />
    </>
  );
}

export default AdminGenerateSeason;
