import React from "react";
import { useParams } from "react-router-dom";
import Roster from "./Roster"; // Import the Roster component

function RosterSpecific() {
  const { leagueId } = useParams();

  return (
    <div>
      <Roster leagueIdProp={leagueId} /> {/* Pass leagueId as a prop */}
    </div>
  );
}

export default RosterSpecific;
