import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Box, Typography } from "@mui/material";

function Footer() {
  const [theYear, setYear] = useState([]);

  useEffect(() => {
    // calculate the year for the footer
    const thisYear = new Date().getFullYear();
    setYear(thisYear);
  }, []);

  return (
    <div>
      <AppBar
        position="fixed" // Set position to fixed
        sx={{
          top: "auto",
          bottom: 0,
          marginTop: 3,
          height: "25px", // Adjust the height as needed
        }}
      >
        {" "}
        {/* Add an AppBar */}
        <Toolbar sx={{ minHeight: "25px !important" }}>
          {/* Conditionally render buttons based on login status */}
            <Box sx={{ flexGrow: 1 }}>
              {" "}
              <Typography variant="overline">
                © {theYear} secfantasy.com
              </Typography>
            </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Footer;
