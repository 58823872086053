import React, { useState, useEffect, useCallback } from "react";
import Menu from "./Menu";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  Tooltip,
  Box,
  Button,
  TextField,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { IconButton } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import Footer from "./Footer";

function RosterExperimental({ leagueIdProp, userIdProp }) {
  const [user] = useAuthState(auth);
  const [squads, setSquads] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const { leagueId, userId } = useParams();
  const [userName, setUserName] = useState("");
  const [squadsByUserLeague, setSquadsByUserLeague] = useState({});
  const [teamName, setTeamName] = useState("My Roster");
  const [editingTeamName, setEditingTeamName] = useState(false);
  const [newTeamName, setNewTeamName] = useState("");

  const positionsOrder = [
    "QB",
    "WR",
    "RB",
    "TE",
    "OL",
    "DL",
    "DB",
    "S",
    "K",
    "ST",
  ];

  const fetchSquads = useCallback(
    async (leagueId) => {
      if (user && leagueId) {
        const squadUsersRef = collection(db, "squadUsers");
        let q;
        if (leagueId && userId) {
          q = query(
            squadUsersRef,
            where("userId", "==", userId),
            where("leagueId", "==", leagueId)
          );
        } else if (leagueId) {
          q = query(
            squadUsersRef,
            where("userId", "==", user.uid),
            where("leagueId", "==", leagueId)
          );
        } else {
          q = query(squadUsersRef, where("userId", "==", user.uid));
        }
        const querySnapshot = await getDocs(q);

        const squadUserData = [];
        querySnapshot.forEach((doc) => {
          squadUserData.push({ id: doc.id, ...doc.data() });
        });

        // Fetch squad details for each squadUser
        const squadPromises = squadUserData.map(async (squadUser) => {
          const squadDoc = await getDoc(doc(db, "squads", squadUser.squadId));
          return { ...squadDoc.data(), squadUserId: squadUser.id };
        });
        const squadsData = await Promise.all(squadPromises);

        const fetchTeamName = async () => {
          if (leagueId) {
            const leagueUsersRef = collection(db, "leagueUsers");
            const q = query(
              leagueUsersRef,
              where("userId", "==", user.uid),
              where("leagueId", "==", leagueId)
            );
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty && querySnapshot.docs[0].data().teamName) {
              setTeamName(querySnapshot.docs[0].data().teamName || "");
            } else {
              setTeamName("My Roster");
            }
          }
        };
        await fetchTeamName();

        return squadsData;
      }
      return [];
    },
    [user, userId]
  );

  useEffect(() => {
    const fetchLeagues = async () => {
      if (user) {
        const leagueUsersRef = collection(db, "leagueUsers");
        const q = query(leagueUsersRef, where("userId", "==", user.uid));
        const querySnapshot = await getDocs(q);
        const leagueIds = querySnapshot.docs.map((doc) => doc.data().leagueId);

        const leaguePromises = leagueIds.map(async (leagueId) => {
          const leagueDoc = await getDoc(doc(db, "leagues", leagueId));
          return { id: leagueId, ...leagueDoc.data() };
        });
        const leaguesData = await Promise.all(leaguePromises);

        setLeagues(leaguesData);

        // Fetch squads for each league
        const squadsByUserLeaguePromises = leaguesData.map(async (league) => {
          const squadUsersRef = collection(db, "squadUsers");
          const q = query(
            squadUsersRef,
            where("userId", "==", user.uid),
            where("leagueId", "==", league.id)
          );
          const querySnapshot = await getDocs(q);
          return [league.id, !querySnapshot.empty];
        });
        const squadsByUserLeagueArray = await Promise.all(
          squadsByUserLeaguePromises
        );
        const squadsByUserLeagueObject = Object.fromEntries(
          squadsByUserLeagueArray
        );

        setSquadsByUserLeague(squadsByUserLeagueObject);

        if (leagueIdProp) {
          // Check if leagueIdProp is provided in the URL
          const selectedLeagueFromURL = leaguesData.find(
            (league) => league.id === leagueIdProp
          );
          if (selectedLeagueFromURL) {
            setSelectedLeague(selectedLeagueFromURL); // Set the league from the URL as selected
          } else {
            // Handle the case where the leagueId in the URL is not found
            console.error("League not found:", leagueIdProp);
            if (leaguesData.length > 0) {
              setSelectedLeague(leaguesData[0]); // Fallback to the first league
            }
          }
        } else if (leaguesData.length > 0) {
          setSelectedLeague(leaguesData[0]); // Set the first league as selected by default
        }

      }
    };

    fetchLeagues();
  }, [user, leagueIdProp]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedLeague) {
        const squadsResult = await fetchSquads(selectedLeague.id);
        setSquads(squadsResult);
      }
    };

    fetchData();
  }, [selectedLeague, fetchSquads]);

  const handleDropSquad = async (squadUserId) => {
    try {
      await deleteDoc(doc(db, "squadUsers", squadUserId));
      fetchSquads();
    } catch (error) {
      console.error("Error dropping squad:", error);
    }
  };

  // Sort squads by position and then by team, using positionsOrder
  const sortedSquads = squads.length
    ? [...squads].sort((a, b) => {
        const positionAIndex = positionsOrder.indexOf(a.position);
        const positionBIndex = positionsOrder.indexOf(b.position);

        if (positionAIndex !== positionBIndex) {
          return positionAIndex - positionBIndex;
        } else {
          return a.team.localeCompare(b.team);
        }
      })
    : [];

  const handleLeagueChange = (league) => {
    setSelectedLeague(league);
  };

  useEffect(() => {
    const fetchUserName = async () => {
      if (userId) {
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          setUserName(userDoc.data().displayName || userDoc.data().email);
        } else {
          setUserName("Unknown User");
        }
      }
    };

    fetchUserName();
  }, [userId]);

  const handleEditTeamName = () => {
    setEditingTeamName(true);
    setNewTeamName(teamName);
  };

  const handleSaveTeamName = async () => {
    try {
      const leagueUsersRef = collection(db, "leagueUsers");
      const q = query(
        leagueUsersRef,
        where("userId", "==", user.uid),
        where("leagueId", "==", selectedLeague.id)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const leagueUserDoc = querySnapshot.docs[0];
        await updateDoc(leagueUserDoc.ref, { teamName: newTeamName });
        setTeamName(newTeamName);
      }
    } catch (error) {
      console.error("Error updating team name:", error);
    } finally {
      setEditingTeamName(false);
    }
  };

  return (
    <div>
      <Menu />
      {/* League selection buttons - swap Box tag below for side-by-side instead of dropdown */}
      {/* <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}> */}
      <Box>
        {leagues.map((league) => (
          <Button
            key={league.id}
            variant={
              selectedLeague?.id === league.id ? "contained" : "outlined"
            }
            size="small"
            onClick={() => handleLeagueChange(league)}
            sx={{
              marginLeft: "10px",
              marginTop: "3px",
              width: "95%",
            }}
            disabled={!squadsByUserLeague[league.id]} // Disable if no squads for this league
          >
            {league.leagueName}
          </Button>
        ))}
      </Box>
      <Divider sx={{ bgcolor: "gray", m: 1 }} variant="fullWidth" />
      {/* Display team name with Edit/Save button */}
      {selectedLeague && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 2,
          }}
        >
          {editingTeamName ? (
            <TextField
              value={newTeamName}
              onChange={(e) => setNewTeamName(e.target.value)}
              sx={{ marginRight: 1 }}
            />
          ) : (
            <Typography variant="h6">{teamName}</Typography>
          )}
          <IconButton
            onClick={editingTeamName ? handleSaveTeamName : handleEditTeamName}
          >
            {editingTeamName ? <CheckIcon /> : <EditIcon />}
          </IconButton>
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2, m: 1 }}>
        {" "}
        <Button
          sx={{ flexGrow: 1 }}
          variant="contained"
          size="small"
          component={Link}
          to={`/squads/${
            selectedLeague
              ? selectedLeague.id
              : leagues.length > 0
              ? leagues[0].id
              : ""
          }`}
        >
          Find Squads
        </Button>{" "}
        {/* Go to latest matchup */}
        <Button
          sx={{ flexGrow: 1 }}
          variant="contained"
          size="small"
          component={Link}
          to={`/matchups/${
            selectedLeague
              ? selectedLeague.id
              : leagues.length > 0
              ? leagues[0].id
              : ""
          }`}
        >
          Matchups
        </Button>
      </Box>
      {/* Roster content */}
      {selectedLeague && (
        <List>
          {sortedSquads.map((squad) => (
            <ListItem key={squad.squadUserId}>
              <Button onClick={() => handleDropSquad(squad.squadUserId)}>
                Drop
              </Button>
              <ListItemAvatar>
                <Avatar
                  alt={`${squad.squadName} logo`}
                  src={`/logos/${squad.logo}`}
                  sx={{ width: 50, height: 50 }}
                />
              </ListItemAvatar>
              <ListItemText primary={squad.squadName} />
              <ListItemSecondaryAction>
                <Tooltip title="Current Points">
                  <Typography variant="body2">
                    {" "}
                    {squad.currentPoints || 0}
                  </Typography>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}

          <Box
            sx={{ display: "flex", justifyContent: "flex-end", mt: 2, mr: 2 }}
          >
            <Typography variant="h8">
              Total Points:{" "}
              {parseFloat(
                squads.reduce(
                  (sum, squad) => sum + (squad.currentPoints || 0),
                  0
                )
              ).toFixed(1)}
            </Typography>
          </Box>
        </List>
      )}
      <Footer />
    </div>
  );
}

export default RosterExperimental;
