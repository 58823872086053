import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import Authentication from "./Authentication";
import Leagues from "./Leagues";
import Roster from "./Roster";
import RosterSpecific from "./RosterSpecific";
import Home from "./Home";
import Squads from "./Squads";
import NewLeague from "./NewLeague";
import LeagueUsers from "./LeagueUsers";
import Profile from "./Profile";
import RosterExperimental from "./RosterExperimental";
import RosterChoice from "./RosterChoice";
import Matchups from "./Matchups";
import AdminGenerateSeason from "./AdminGenerateSeason";
import ACC from "./ACC"

function App() {
  const [user] = useAuthState(auth);

  return (
    <BrowserRouter>
      <div style={{ marginBottom: "30px" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/login"
            element={user ? <Navigate to="/leagues" /> : <Authentication />}
          />
          <Route
            path="/leagues"
            element={user ? <Leagues /> : <Navigate to="/login" />}
          />
          <Route
            path="/roster"
            element={user ? <Roster /> : <Navigate to="/login" />}
          />
          <Route
            path="/roster/:leagueId"
            element={user ? <RosterSpecific /> : <Navigate to="/login" />}
          />
          <Route
            path="/squads/:leagueId"
            element={user ? <Squads /> : <Navigate to="/login" />}
          />
          <Route
            path="/newLeague"
            element={user ? <NewLeague /> : <Navigate to="/login" />}
          />
          <Route
            path="/roster/:leagueId/:userId"
            element={user ? <Roster /> : <Navigate to="/login" />}
          />
          <Route
            path="/roster"
            element={user ? <Roster /> : <Navigate to="/login" />}
          />
          <Route
            path="/leagueUsers/:leagueId"
            element={user ? <LeagueUsers /> : <Navigate to="/login" />}
          />
          <Route
            path="/profile"
            element={user ? <Profile /> : <Navigate to="/login" />}
          />
          <Route
            path="/rosterExperimental"
            element={user ? <RosterExperimental /> : <Navigate to="/login" />}
          />
          <Route
            path="/rosterExperimental/:leagueId"
            element={user ? <RosterExperimental /> : <Navigate to="/login" />}
          />
          <Route
            path="/rosterChoice"
            element={user ? <RosterChoice /> : <Navigate to="/login" />}
          />
          <Route
            path="/matchups/:leagueId"
            element={user ? <Matchups /> : <Navigate to="/login" />}
          />
          <Route
            path="/adminGenSeason"
            element={user ? <AdminGenerateSeason /> : <Navigate to="/login" />}
          />
          <Route
            path="/acc"
            element={<ACC />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

export default App;
