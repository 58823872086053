// AutoFill.js
import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./firebase";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  serverTimestamp,
  orderBy,
  limit,
} from "firebase/firestore";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function AutoFill({ leagueId, onAutoFill }) {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [isAutoFilling, setIsAutoFilling] = useState(false);

  const handleAutoFillRoster = async () => {
    try {
      setIsAutoFilling(true);

      if (!user) {
        console.error("User not logged in.");
        return;
      }

      const positions = [
        "QB",
        "WR",
        "TE",
        "OL",
        "RB",
        "DL",
        "DB",
        "S",
        "K",
        "ST",
      ];

      for (const position of positions) {
        // 1. Get the squad with the highest currentPoints for the position
        const squadsRef = collection(db, "squads");
        const q = query(
          squadsRef,
          where("position", "==", position),
          orderBy("currentPoints", "desc"),
          limit(1)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          console.log(`No squad found for position ${position}`);
          continue;
        }

        const bestSquad = querySnapshot.docs[0].data();
        const bestSquadId = querySnapshot.docs[0].id;

        // 2. Check if the user already has a squad for this position
        const squadUsersRef = collection(db, "squadUsers");
        const userSquadQuery = query(
          squadUsersRef,
          where("userId", "==", user.uid),
          where("position", "==", position),
          where("leagueId", "==", leagueId) // Add leagueId to the query
        );
        const userSquadSnapshot = await getDocs(userSquadQuery);

        if (!userSquadSnapshot.empty) {
          console.log(
            `User already has a squad for position ${position} in this league, skipping.`
          );
          continue;
        }

        // 3. Add the best squad to the user's roster
        await addDoc(collection(db, "squadUsers"), {
          userId: user.uid,
          leagueId: leagueId,
          squadId: bestSquadId,
          position: bestSquad.position,
          createdAt: serverTimestamp(),
        });

        console.log(`Added squad ${bestSquad.squadName} to user's roster.`);
      }

      console.log("Auto-filled roster successfully!");
      onAutoFill(); // Re-fetch available squads after auto-fill
      // Redirect to the roster page after auto-filling
      navigate(`/roster/${leagueId}`);
    } catch (error) {
      console.error("Error auto-filling roster:", error);
    } finally {
      setIsAutoFilling(false); // Set loading state to false in the finally block
    }
  };

  return (
    <Button
      sx={{ margin: "10px" }}
      variant="outlined"
      onClick={handleAutoFillRoster}
      disabled={isAutoFilling} // Disable the button while loading
    >
      {/* Conditionally render button text */}
      {!isAutoFilling ? "Auto-Fill Roster" : "Autofilling..."}{" "}
    </Button>
  );
}

export default AutoFill;
