import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAPvXG0kSdRrzIDw4bT8gEgqPizyl0ZGjs",
  authDomain: "sec-fantasy.firebaseapp.com",
  projectId: "sec-fantasy",
  storageBucket: "sec-fantasy.appspot.com",
  messagingSenderId: "719222739809",
  appId: "1:719222739809:web:28f09630eff2109d91ab20",
  measurementId: "G-V5M0PWKCXX",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
